import React from 'react';
import { graphql } from 'gatsby'
import About from 'components/About';
import Layout from "components/Layout";

export default class AboutPage extends React.Component {
  render() {
    const { aboutImg, cardImg } = this.props.data;
    return <Layout
      location={this.props.location}
      title={"Stonybrook Strings | Our Approach"}
      description={"At Stonybrook Strings, students find a nurturing and rigorous learning experience where their creative, intellectual, and emotional gifts are developed and strengthened."}
    >
      <About aboutImg={aboutImg.childImageSharp.gatsbyImageData} cardImg={cardImg.childImageSharp.gatsbyImageData}/>
    </Layout>;
  }
}

export const query = graphql`
  query AboutPageQuery {
    aboutImg: file(relativePath: { eq: "group-games.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1000)
      }
    }

    cardImg: file(relativePath: { eq: "look.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout:FIXED, width: 150, height: 150)
      }
    }
  }
`;