import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image"

import Text from 'components/Text';
import Button from 'components/Button';
// import SubscriptionForm from 'components/Subscribe';
import * as styles from './styles.module.scss';

export default class About extends React.Component {
  render() {
    return (
      <div className={styles.root}>
        <div className={styles.titleWrapper}>
          <GatsbyImage className={styles.img} image={this.props.aboutImg} alt={"Music Puzzles"}/>
          <h1>About</h1>
        </div>

        <Text className={styles.about}>
          <div className={styles.callOut}>
            <p className={styles.highlight}>At Stonybrook Strings, music becomes a lifestyle.</p>
          </div>

          <p>
            Students will find a nurturing, creative, yet rigorous learning experience where their
            creative, intellectual, emotional gifts are developed and strengthened.{' '}
            <span className={styles.bold}>Each student is known and valued deeply.</span>
          </p>

          <p>
            Frequent <span className={styles.bold}>performance</span> opportunities,{' '}
            <span className={styles.bold}>creative projects</span>, and{' '}
            <span className={styles.bold}>community engagement</span> initiatives engage students’
            musicianship by connecting their learning with the world around them. Students broaden
            their horizons by joining local orchestras, attending summer music festivals, performing
            in masterclasses, and attending live concerts.
          </p>

          <p>
            By opening lines of communication through{' '}
            <span className={styles.bold}>parental involvement</span> in lessons and practice,{' '}
            <span className={styles.bold}>group playing experiences</span>, and community events,
            students and parents are motivated and supported by one another.
          </p>

          <p>
            Students learn to listen carefully and appreciate{' '}
            <span className={styles.bold}>high musical standards</span> from the beginning — a high
            level of commitment is expected, which includes consistent and effective{' '}
            <span className={styles.bold}>daily practice</span>. To achieve these{' '}
            <span className={styles.bold}>learning outcomes</span>, students are provided a detailed
            extensive support during and beyond the lesson hour. The incremental progress through
            daily practice is the “secret” ingredient that creates true{' '}
            <span className={styles.bold}>growth and confidence</span>. This is how music will
            change your life!
          </p>

          <p>
            In the end, the goal is for each student to learn the violin with{' '}
            <span className={styles.bold}>joy</span>, confidence, and skill, and to live as
            thoughtful, engaged, and self-motivated individuals.
          </p>

          <Button to="/contact/" className={styles.button} text="Let's begin" />
        </Text>

        <div className={styles.noteCard}>
          <GatsbyImage
            className={`img circle ${styles.noteImg}`}
            image={this.props.cardImg}
            style={{ position: 'absolute' }}
            alt={"Maggie Cerjan"}
          />
          <h5 className={styles.noteTitle}>From the Director</h5>
          <p>
            Learning the violin should be a holistic and joyful process. This is what I strive to
            bring to my students each week. Students benefit from my multi-faceted experience as a
            highly trained and experienced educator-performer, and my commitment to continued
            education in my own practice. I use my training in the Suzuki method, the{' '}
            <a href="https://aosa.org/about/what-is-orff-schulwerk/" target="_blank" rel="noopener noreferrer">Orff-Schulwerk process</a>, mindfulness practices, music theory, and habit
            and practice coaching, to create a curriculum that inspires each student.
          </p>
          <p>
            I have personally learned the most from mentors who cared deeply about not only my
            musical and technical ability, but also my well-being and creative interests. I have
            found that success for students comes when they feel well-supported and heard, and also
            challenged frequently. I give my students and their families what they need to excel at
            music in an environment that adapts to their personalities, supports their passions, and
            allows them to give back to the community through beautiful music.
          </p>
        </div>

        {/*<SubscriptionForm text="Get the scoop from Stonybrook Strings in your inbox:" />*/}
      </div>
    );
  }
}
